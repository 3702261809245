import { render, staticRenderFns } from "./AdminsPage.vue?vue&type=template&id=00c93f5f&scoped=true&"
import script from "./AdminsPage.vue?vue&type=script&lang=ts&"
export * from "./AdminsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c93f5f",
  null
  
)

export default component.exports