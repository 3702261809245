


























import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { TableOptions } from "../types";
import { Admin, AdminsFilterEnum } from "../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../store/modules/tables";
import Modal from "../components/commons/Modal.vue";
import { EventBus } from "../enums/EventBus";
import { eventBus } from "../utils/eventBus";
import AdminDeleteModal from "../components/app/admins/AdminDeleteModal.vue";
import AdminCreateModal from "../components/app/admins/AdminCreateModal.vue";
import { Routes } from "../enums/Routes";
import { Getter } from "vuex-class";
import { authNamespace, AuthGetters } from "../store/modules/auth";

@Component({
  components: {
    AppLayout,
    Button,
    Modal,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    AdminDeleteModal,
    AdminCreateModal,
  },
})
export default class AdminsPage extends Vue {
  @Getter(`${authNamespace}/${AuthGetters.GetAdministrator}`)
  admin!: Admin;

  tableOptions: TableOptions<Admin, AdminsFilterEnum> = {
    defaultSortBy: ["surname"],
    headers: [
      {
        text: "Jméno",
        sortable: true,
        align: "start",
        value: "firstname",
      },
      {
        text: "Příjmení",
        sortable: true,
        align: "start",
        value: "surname",
      },
      { text: "Email", sortable: true, align: "start", value: "username" },
      {
        text: "Superadmin",
        sortable: true,
        align: "start",
        value: "isSuperAdmin",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.AdminsTable,
  };

  deleteItem(admin: Admin): void {
    eventBus.$emit(EventBus.AdminDeleteModal, admin);
  }

  addItem(): void {
    eventBus.$emit(EventBus.AdminCreateModal);
  }

  updateItem(admin: Admin): void {
    this.$router.push({
      name: Routes.UserUpdate,
      params: { id: admin.id.toString() },
    });
  }
}
